<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-2">
      <label class="text-sm font-medium text-gray-500">{{ label }}</label>
      <div class="w-full relative" v-click-outside="closeDropdown">
        <div class="w-full">
          <input
              @click="handleOpenDropDown"
              @input="handleSearch"
              v-model="searchValue"
              type="text"
              autocomplete="off"
              class="w-full cursor-pointer h-formInput bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
          <FormInputArrowIcon />
        </div>
        <div class="absolute w-full rounded-md bg-white">
          <ul v-if="isOpen && users.length"
              class="absolute z-50 w-full bg-white max-h-60 rounded-md py-1 ring-opacity-5 overflow-auto focus:outline-none text-sm border border-gray-300">
            <li v-for="user in users"
                :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                         'text-white bg-primary': user.id === selectedUser.id,
                         'text-gray-900 hover:bg-gray-50': user.id !== selectedUser.id
                }]"
                :key="user.id"
                @click="handleSelectUser(user.id)"
            >
              <div class="flex items-center">
                <div>
                  <div v-if="user.avatar" class="flex-shrink-0">
                    <img class="h-5 w-5 rounded-full" :src="user.avatar" alt="">
                  </div>
                  <div v-else>
                    <div class="h-5 w-5 rounded-full bg-gray-200 flex items-center text-10 text-center">
                      <span class="block w-full text-gray-900">{{ user.name | getNameAbbr }}</span>
                    </div>
                  </div>
                </div>
                <div class="ml-3">
                  <span class="font-normal block truncate">{{ user.name }}</span>
                </div>
              </div>
              <span
                  v-if="user.id === selectedUser.id"
                  class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                     :fill="user.id === selectedUser.id ? 'white' : 'currentColor'">
                  <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"/>
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <ErrorMessage v-if="errorMessage.length" :messages="[errorMessage[0]]"/>
    </div>
  </div>
</template>

<script>
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';

export default {
  name: 'DeliveredBySelectOption',
  components: {
    FormInputArrowIcon,
    ErrorMessage
  },
  directives: {
    ClickOutside
  },
  props: {
    label: {
      type: String,
      required: true
    },
    defaultDeliver: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: Array,
      'default': Array
    }
  },
  data() {
    return {
      isOpen: false,
      searchValue: '',
      users: [],
      selectedUser: {}
    }
  },
  computed: {
    ...mapGetters(['assigneeUsers'])
  },
  watch: {
    assigneeUsers(newValue) {
      this.users = newValue;
      this.defaultDeliver && this.setDefaultValue();
    }
  },
  mounted() {
    this.handleGetAssigneeUsers();
  },
  methods: {
    ...mapActions({
      handleGetAssigneeUsers: 'getAssigneeUsers'
    }),
    closeDropdown() {
      this.isOpen = false;
    },
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
    },
    handleSearch() {
      this.isOpen = true;

      this.users = this.assigneeUsers.filter((user) => {
        if (user.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return user;
        }
      });
    },
    handleSelectUser(id) {
      this.selectedUser = this.users.find(user => user.id === id);
      this.searchValue = this.selectedUser.name;
      this.isOpen = false;
      this.$emit('handleChangeDeliver', this.selectedUser);
    },
    setDefaultValue() {
      this.searchValue = this.defaultDeliver.name;
    }
  }
}
</script>

<style scoped>

</style>
