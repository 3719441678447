<template>
  <div>
    <div v-if="!editable" className="fieldTitle">
      <SelectOptionLabel
          :label="$t('task.fields.due')"
          :name="date"
          :isIcon="false"
          :isDate="true"
      />
    </div>
    <div v-else class="w-full flex items-start justify-start flex-col gap-2">
      <label class="text-sm font-medium text-gray-500">{{ $t('task.fields.due') }}</label>
      <datepicker
          :value="date"
          :language="locale"
          :format="$i18n.locale === 'fi' ? 'd-M-yyyy' : 'dd-MM-yyyy'"
          placeholder="Select Date"
          @input="handleChange"
          input-class="w-full h-formInput cursor-pointer focus:ring-gray-300 focus:outline-none focus:ring-opacity-0 rounded-md focus:border-gray-300 sm:text-sm border-gray-300"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import SelectOptionLabel from '@/components/SelectOptions/SelectOptionLabel';
import { en, fi } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'DatePicker',
  components: {
    SelectOptionLabel,
    Datepicker
  },
  props: {
    defaultValue: {
      String
    },
    editable: {
      type: Boolean,
      required: true
    },
    inputName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      date: '',
      disabledDates: moment().subtract(1, 'days').toDate()
    }
  },
  watch: {
    defaultValue() {
      this.setDefaultValue();
    }
  },
  mounted() {
    this.setDefaultValue();
    const date = new Date();
    date.setDate(date.getDate() - 1);
    this.disabledDates.to = moment();
  },
  methods: {
    handleChange(date) {
      this.date = moment(date).format('YYYY-M-DD');
      this.$emit('handleInputFieldChange', { value: this.date, inputName: this.inputName });
    },
    setDefaultValue() {
      this.defaultValue ? this.date = moment(this.defaultValue).format('YYYY-MMM-DD') : this.date = '';
    }
  },
  computed: {
    locale() {
      return localStorage.getItem('locale') === 'en' ? en : fi
    }
  }
}
</script>

<style lang="scss">
@import '../../sass/mixins.scss';

.vdp-datepicker {
  width: 100%;
}

.vdp-datepicker__calendar {
  width: 100% !important;
}

.cell.day:hover {
  border-color: #132c40;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid $primaryBackground !important;
}

.vdp-datepicker__calendar .cell.selected {
  background: $primaryBackground !important;
  color: #ffffff !important;
}

.vdp-datepicker__calendar .cell.selected:hover {
  background: $primaryHover !important;
}
</style>
