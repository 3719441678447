<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-2">
      <label class="text-sm font-medium text-gray-500">{{ $t('product.fields.title') }}</label>
      <div class="w-full relative" v-click-outside="closeDropdown">
        <div class="w-full">
          <input
              @click="handleOpenDropDown"
              @input="handleSearch"
              v-model="searchValue"
              type="text"
              autocomplete="off"
              class="w-full cursor-pointer h-formInput bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
          <FormInputArrowIcon />
        </div>
        <div class="absolute w-full rounded-md bg-white">
          <ul v-if="isOpen && products.length"
              class="absolute z-50 w-full bg-white max-h-60 rounded-md py-1 ring-opacity-5 overflow-auto focus:outline-none text-sm border border-gray-300">
            <li v-for="product in products"
                :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                         'text-white bg-primary': product.id === selectedProduct.id,
                         'text-gray-900 hover:bg-gray-50': product.id !== selectedProduct.id
                }]"
                :key="product.id"
                @click="handleSelectProduct(product.id)"
            >
              <span class="font-normal block truncate">{{ product.name }}</span>
              <span
                  v-if="product.id === selectedProduct.id"
                  class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                     :fill="product.id === selectedProduct.id ? 'white' : 'currentColor'">
                  <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"/>
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <ErrorMessage v-if="errorMessage.length" :messages="[errorMessage[0]]"/>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import { mapActions, mapGetters } from 'vuex';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';

export default {
  name: 'ProductsOption',
  components: {
    FormInputArrowIcon,
    ErrorMessage
  },
  directives: {
    ClickOutside
  },
  props: {
    defaultProduct: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: Array,
      'default': Array
    }
  },
  data() {
    return {
      isOpen: false,
      products: [],
      selectedProduct: {},
      searchValue: ''
    }
  },
  computed: {
    ...mapGetters(['productsSelectOption'])
  },
  watch: {
    productsSelectOption(newValue) {
      this.products = newValue;
      this.defaultProduct && this.setDefaultValue();
    }
  },
  mounted() {
    this.handleGetProducts();
  },
  methods: {
    ...mapActions({
      handleGetProducts: 'fetchProductsSelectOption'
    }),
    closeDropdown() {
      this.isOpen = false;
    },
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
    },
    handleSearch() {
      this.isOpen = true;

      this.products = this.productsSelectOption.filter((product) => {
        if (product.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return product;
        }
      });
    },
    handleSelectProduct(id) {
      this.selectedProduct = this.products.find(product => product.id === id);
      this.searchValue = this.selectedProduct.name;
      this.isOpen = false;
      this.$emit('handleChangeProduct', this.selectedProduct);
    },
    setDefaultValue() {
      this.searchValue = this.defaultProduct.name;
    }
  }
}
</script>

<style scoped>

</style>
